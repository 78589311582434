.bubble {
    z-index: -1;
    background-color: #f5f5f5;
    border-radius: 50%;
    position: fixed;
    bottom: 0;
}

@keyframes moveUp {
    0% {
        transform: translateY(30rem);
        opacity: .3;
    }
    100% {
        transform: translateY(-50rem);
        opacity: 0;
    }
}