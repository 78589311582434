/* import a font */

* {
    font-family: "LTInstitute" !important;
    font-weight: 400;
    font-style: normal;
    font-display: swap;


    user-select: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100vw;
    overflow-x: hidden;
    background-color: #331b36;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}