.line-1 {
    position: relative;
    width: 9em;
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
}

/* Animation */
.anim-typewriter {
    animation: typewriter 1s steps(16) 0s 1 normal both,
        blinkTextCursor 1000ms steps(2) infinite normal;
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 8.6em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, .75);
    }

    to {
        border-right-color: transparent;
    }
}